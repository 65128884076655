import { useCallback, useEffect, useState, useImperativeHandle, type ChangeEvent, RefObject } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import MaskedInput from '../MaskedInput';
import * as Yup from 'yup';
import CustomValidation from '@mediashop/app/helper/DynamicAddressForm/CustomValidation';
import { FormSubmitHandle } from '../../molecule/DynamicFormik';
import DynamicFormikErrorFocus from '../../molecule/DynamicFormik/DynamicFormikErrorFocus';
import noop from 'lodash/noop';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const MIN_BIRTH_YEAR = 1915;

const nanToDefault = (defaultValue: number) => (value) => isNaN(value) ? defaultValue : value;

const getValidationSchema = (inputName: InputName) => ({
    [inputName.day]: Yup.number()
        .transform(nanToDefault(1))
        .required('address.birthdateDayErrorText')
        .min(1, 'address.birthdateDayErrorText')
        .max(31, 'address.birthdateDayErrorText'),
    [inputName.month]: Yup.number()
        .transform(nanToDefault(1))
        .required('address.birthdateMonthErrorText')
        .min(1, 'address.birthdateMonthErrorText')
        .max(12, 'address.birthdateMonthErrorText'),
    [inputName.year]: Yup.number()
        .transform(nanToDefault(MIN_BIRTH_YEAR))
        .required('address.birthdateYearErrorText')
        .min(MIN_BIRTH_YEAR, 'address.birthdateYearErrorText')
        .max(new Date().getFullYear() - 18, 'address.birthdateYearErrorText'),
});

type InputName = {
    day: string;
    month: string;
    year: string;
};

type DateOfBirthProps = BaseProps & {
    name: string;
    refId?: RefObject<FormSubmitHandle>;
    required?: boolean;
    defaultValue?: string;
    error?: string;
    label?: string;
    onChange: ({ date, isValid, name }: { date: string; isValid: boolean; name: string }) => void;
};

const componentName = 'date-of-birth-input';

const inputName = {
    day: 'customerBirthDay',
    month: 'customerBirthMonth',
    year: 'customerBirthYear',
};

/**
 * This component renders a fieldset of birthday input fields
 * @param name
 * @param refId
 * @param inputName
 * @param required
 * @param defaultValue
 * @param error
 * @param labelId
 * @param onChange
 * @constructor
 */
// eslint-disable-next-line max-lines-per-function
function DateOfBirthInput({
    name,
    refId,
    required = false,
    defaultValue,
    error = '',
    label,
    onChange,
}: DateOfBirthProps) {
    const intl = useIntl();
    const [isValid, setIsValid] = useState(true);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [inputName.day]: '',
            [inputName.month]: '',
            [inputName.year]: '',
        },
        validationSchema: Yup.object(getValidationSchema(inputName)),
        onSubmit: noop,
    });

    const handleDateChange = useCallback((dateValue: string) => {
        const isValid = CustomValidation.isValidDate(dateValue);
        setIsValid(isValid);
        onChange({
            date: isValid ? dateValue : '',
            isValid: dateValue === '0000-00-00' ? true : isValid,
            name,
        });
    }, []);

    useImperativeHandle(refId, () => ({
        submit: async () => {
            await formik.submitForm();
            formik.setSubmitting(false);
        },
    }));

    const getDateString = () => {
        const year = formik.values[inputName.year].padStart(4, '0');
        const month = formik.values[inputName.month].padStart(2, '0');
        const day = formik.values[inputName.day].padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setIsValid(true);
        const dateValue = getDateString();
        handleDateChange(dateValue);
    }, [formik.values]);

    useEffect(() => {
        if (defaultValue) {
            formik.setValues({
                [inputName.day]: defaultValue.substring(8, 10),
                [inputName.month]: defaultValue.substring(5, 7),
                [inputName.year]: defaultValue.substring(0, 4),
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        if (error) {
            formik.submitForm();
        }
    }, [error]);

    function getError(type: 'day' | 'month' | 'year') {
        let error: string | undefined = '';

        // if required show error after field is touched
        if (required) {
            if (formik.touched[inputName[type]] && formik.errors[inputName[type]]) {
                error = formik.errors[inputName[type]]?.toString();
            }
        }
        // if not required show error after field is touched and value exists
        if (formik.values[inputName[type]] && formik.touched[inputName[type]] && formik.errors[inputName[type]]) {
            error = formik.errors[inputName[type]]?.toString();
        }

        return error ? intl.formatMessage({ id: error }) : error;
    }

    const commonInputFields = {
        pattern: '\\d*',
        required,
        type: 'tel',
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replaceAll('_', '');
        formik.handleChange(event);
        formik.handleBlur(event);
    };

    const [focusMonthInput, setFocusMonthInput] = useState(false);
    const [focusYearInput, setFocusYearInput] = useState(false);

    const onDayInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(event);
        const inputValue = event.target.value;

        if (!inputValue.includes('_') && inputValue !== EMPTY_STRING) {
            setFocusMonthInput(true);
        }
    };

    const onMonthInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(event);
        const inputValue = event.target.value;

        if (!inputValue.includes('_') && inputValue !== EMPTY_STRING) {
            setFocusYearInput(true);
        }
    };

    return (
        <div className={classNames(componentName)}>
            {label ? <div className={`${componentName}__label`}>{required ? `${label}*` : label}</div> : SKIP_RENDER}

            <div className={classNames(`${componentName}__inputs`)}>
                <MaskedInput
                    {...commonInputFields}
                    mask="99"
                    name={inputName.day}
                    className={`${componentName}__masked-input`}
                    value={formik.values[inputName.day]}
                    error={getError('day')}
                    onBlur={handleBlur}
                    onChange={onDayInputChange}
                    label={intl.formatMessage({ id: 'address.dayPlaceholder' })}
                />
                <MaskedInput
                    {...commonInputFields}
                    focus={focusMonthInput}
                    mask="99"
                    name={inputName.month}
                    className={`${componentName}__masked-input`}
                    value={formik.values[inputName.month]}
                    error={getError('month')}
                    onBlur={handleBlur}
                    onChange={onMonthInputChange}
                    label={intl.formatMessage({ id: 'address.monthPlaceholder' })}
                />
                <MaskedInput
                    {...commonInputFields}
                    focus={focusYearInput}
                    mask="9999"
                    name={inputName.year}
                    className={`${componentName}__masked-input`}
                    value={formik.values[inputName.year]}
                    error={getError('year')}
                    onBlur={handleBlur}
                    onChange={formik.handleChange}
                    label={intl.formatMessage({ id: 'address.yearPlaceholder' })}
                />
            </div>
            {(formik.values[inputName.day] || formik.values[inputName.month] || formik.values[inputName.year]) &&
                !isValid && (
                    <span className={classNames(`${componentName}__error`)}>
                        {CustomValidation.isTooYoung(getDateString()) ? (
                            <FormattedMessage id="address.birthdateTooYoungErrorText" />
                        ) : (
                            <FormattedMessage id="address.birthdateErrorText" />
                        )}
                    </span>
                )}
            <DynamicFormikErrorFocus
                isSubmitting={formik.isSubmitting}
                isValidating={formik.isValidating}
                errors={formik.errors}
            />
        </div>
    );
}

export default injectComponent('pattern.atom.DateOfBirthInput', DateOfBirthInput);
