import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProjectStateLoading {
    loading: boolean;
    key?: string;
    queries?: string[];
    categoryTreeRootKey: string;
    takeLanguageFromUrl: boolean;
    basePath: string;
    store: string;
}

export interface ProjectStateFinished {
    loading: boolean;
    key: string;
    platform: 'SPLP' | 'Catalog';
    theme: string;
    newrelicSettingsID: string;
    gtmContainer: {
        id: string;
        auth: string;
        preview: string;
    };
    bloomreachApiEndpoint: string;
    bloomreachImageEndpoint?: string;
    redirects: Record<string, string>[];
    shippingCountries: string[];
    locales: string[];
    currencies: string[];
    basePath: string;
    queries?: string[];
    categoryTreeRootKey: string;
    categoryCountries?: Record<string, string>;
    takeLanguageFromUrl: boolean;
    store: string;
    emarsysMerchantId?: string;
    emarsysAccountCurrency?: string;
    customerClubMembershipCard?: string;
    customerClubMembershipExtension?: string;
    catalogRequestEmail?: string;
    nostoAccounts?: Record<string, string>;
    searchProvider?: string;
    shopNames: Record<string, string>[];
    payPalClientId?: Array<{
        clientId: string;
        countries: Array<string>;
    }>;
}

type ProjectState = ProjectStateLoading | ProjectStateFinished;

const initialState: ProjectState = {
    loading: true,
    categoryTreeRootKey: '',
    takeLanguageFromUrl: false,
    basePath: '',
    store: '',
};

/**
 * Project configuration reducer.
 */
const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        configurationReceived(state, action: PayloadAction<{ config: any }>) {
            const { config } = action.payload;
            return {
                ...state,
                loading: false,
                ...config,
            };
        },
    },
});

export const { configurationReceived } = projectSlice.actions;

export default projectSlice.reducer;
