import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement, RefObject } from 'react';

const CountrySelectContainerLazy = lazy(() => lazyRetry(() => import('./CountrySelectContainer')));

export type CountrySelectContainerProps = BaseProps & {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    wrapperRef?: RefObject<HTMLElement>;
};

const CountrySelectContainer = (props: CountrySelectContainerProps): ReactElement => (
    <LazyComponent>
        <CountrySelectContainerLazy {...props} />
    </LazyComponent>
);

export default CountrySelectContainer;
