import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useProject } from '@mediashop/app/hooks/useProject';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import { CSSProperties, useEffect, useRef } from 'react';
import SearchBar from '../../molecule/SearchBar';
import UserMenu from '../MainNavigation/UserMenu';
import BurgerMenuButton from './BurgerMenu/BurgerMenuWrapper';
import DesktopNavigation from './DesktopNavigation';
import HeaderLogoCustom from './HeaderLogoCustom';
import LiveTVButton from './LiveTVButton/LiveTVButton';
import SpecialButton from './SpecialButton';
import { MainNavigationCustomProps } from './types';

const componentName = 'main-navigation-custom';

const MainNavigationCustom = ({
    mainNavigation,
    navigation,
    quickLinks,
    searchbar,
    specialButton,
    liveTvButton,
    userLinks,
}: MainNavigationCustomProps) => {
    const { mainNavigationLogo, headerSeparatorColorHex, headerBackgroundColorHex } = mainNavigation;
    const { navigationItems, dropdownBackgroundColorHex, activeItemFontColorHex, itemFontColorHex } = navigation;
    const {
        backgroundColorHex: searchBarBackgroundColor,
        borderColorHex: searchBarBorderColor,
        fontColorHex: searchBarFontColor,
    } = searchbar;
    const { separatorColorHex: quickLinksSeperatorColor } = quickLinks;
    const { userIconLinkLoggedOut, userIconLinkLoggedIn, linksForLoggedInUsers } = userLinks;

    const ref = useRef<HTMLDivElement>(null);

    const { searchProvider } = useProject();

    const isSearchActive = searchProvider === 'Nosto' || searchProvider === 'Findologic';

    const styles = {
        [`--${componentName}-separator-color`]: headerSeparatorColorHex,
        [`--${componentName}-background-color`]: headerBackgroundColorHex,
        [`--${componentName}-search-background-color`]: searchBarBackgroundColor,
        [`--${componentName}-search-border-color`]: searchBarBorderColor,
        [`--${componentName}-search-font-color`]: searchBarFontColor,
        [`--${componentName}-font-color`]: itemFontColorHex,
        [`--${componentName}-active-color`]: activeItemFontColorHex,
        [`--${componentName}-dropdown-background-color`]: dropdownBackgroundColorHex,
        [`--${componentName}-quick-links-separator-color`]: quickLinksSeperatorColor,
    } as CSSProperties;

    useEffect(() => {
        if (ref.current) {
            document.documentElement.style.setProperty(
                '--overlay-background-top',
                `${ref.current.getBoundingClientRect().top}px`
            );
        }
    }, []);

    return (
        <div ref={ref} className={componentName} style={styles}>
            <ContentWrapper>
                <div className={`${componentName}__inner`}>
                    <div className={`${componentName}__logo`}>
                        <BurgerMenuButton
                            navigation={navigation}
                            specialButton={specialButton}
                            quickLinks={quickLinks}
                        />
                        <HeaderLogoCustom {...mainNavigationLogo} />
                    </div>

                    <div className={`${componentName}__meta`}>
                        <div className={`${componentName}__meta-left`}>
                            <div className={`${componentName}__search`}>
                                {isSearchActive ? <SearchBar isMainNavigationCustom /> : SKIP_RENDER}
                            </div>

                            {specialButton ? <SpecialButton {...specialButton} /> : SKIP_RENDER}

                            {liveTvButton ? <LiveTVButton {...liveTvButton} /> : SKIP_RENDER}
                        </div>
                        <div className={`${componentName}__meta-right`}>
                            <BurgerMenuButton
                                navigation={navigation}
                                specialButton={specialButton}
                                quickLinks={quickLinks}
                            />
                            <UserMenu
                                linksForLoggedInUsers={linksForLoggedInUsers}
                                userIconLinkLoggedIn={userIconLinkLoggedIn}
                                userIconLinkLoggedOut={userIconLinkLoggedOut}
                            />
                        </div>
                    </div>

                    <div className={`${componentName}__nav`}>
                        <DesktopNavigation navigationItems={navigationItems} />
                    </div>

                    {isSearchActive ? (
                        <div className={`${componentName}__mobile-search`}>
                            <SearchBar isMainNavigationCustom />
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            </ContentWrapper>
        </div>
    );
};

export default MainNavigationCustom;
