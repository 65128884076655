import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import React from 'react';
import { LiveTVButtonProps } from './index';

const componentName = 'live-tv-button';

const LiveTVButton = ({ link, tab }: LiveTVButtonProps) => {
    return (
        <div className={componentName}>
            <LinkOpenInTab tab={tab} link={link}>
                LiveTV <span className={`${componentName}__dot`}></span>
            </LinkOpenInTab>
        </div>
    );
};

export default LiveTVButton;
