import CartClient from '../../api/CartClient';
import { RootStore } from '../index';

import { AddressForm } from '../../api/types/Address';
import { Cart } from '../../api/types/ClientCart';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { Variant } from '../../api/types/ClientProduct';
import ProductClient from '../../api/ProductClient';
import { v4 as uuidv4 } from 'uuid';
import {
    cartReceived,
    cartReset,
    installmentPlanReceived,
    installmentPlanReset,
    lineItemAdded,
    loadingCartFailed,
    loadingCartStarted,
} from '../reducer/cart';
import { SubscriptionInterval } from '@mediashop/catalog-base/pattern/molecule/AboSelector/AboSelector';
import { mapBrxRelationTypeToGatewayRelationType } from '../../api/mapper/ProductRelationMapper';
import { partnerUpdated } from '../reducer/partner';

type AddLineItemProps = {
    partner?: string;
    quantity: number;
    relation?: {
        mainProductSku: string;
        relationType: BrxRelationType;
    };
    subscriptionInterval?: SubscriptionInterval;
    variant: Variant;
    wkoSku?: string;
    facebookTrackingId?: string;
};

type AddLineItemSimpleProps = {
    partner?: string;
    sku: string;
    quantity: number;
};

export default class CartLoader {
    private client: CartClient;
    private productClient: ProductClient;
    private store: RootStore;

    constructor(client: CartClient, productClient: ProductClient, store: RootStore) {
        this.client = client;
        this.productClient = productClient;
        this.store = store;
    }

    async addLineItem(props: AddLineItemProps): Promise<{ exceededMaxQuantity: boolean }> {
        const facebookTrackingId = uuidv4();
        this.store.dispatch(loadingCartStarted());

        const addLineItemToCartProps = { ...props, facebookTrackingId };

        const { cart, exceededMaxQuantity } = await this.addLineItemToCart(addLineItemToCartProps);
        this.store.dispatch(cartReceived({ cart }));
        this.store.dispatch(partnerUpdated({ partnerId: cart.customFields?.partnerId ?? '' }));

        const {
            partner,
            quantity,
            variant: { sku },
        } = props;
        this.store.dispatch(lineItemAdded({ sku, quantity, partner, facebookTrackingId }));

        return {
            exceededMaxQuantity,
        };
    }

    async addLineItemSimple({ quantity, sku, partner }: AddLineItemSimpleProps): Promise<void> {
        const facebookTrackingId = uuidv4();
        this.store.dispatch(loadingCartStarted());

        const cart = await this.client.addLineItem({
            sku,
            quantity,
            partner,
            facebookTrackingId,
        });
        this.store.dispatch(cartReceived({ cart }));
        this.store.dispatch(partnerUpdated({ partnerId: cart.customFields?.partnerId ?? '' }));

        this.store.dispatch(lineItemAdded({ sku, quantity, partner, facebookTrackingId }));
    }

    // eslint-disable-next-line complexity
    private async handleCheaperSet({
        partner,
        quantity,
        relation,
        subscriptionInterval,
        variant,
        wkoSku,
        facebookTrackingId,
    }: AddLineItemProps): Promise<{ cart: Cart; exceededMaxQuantity: boolean }> {
        const { cart } = this.store.getState().cart;
        let alreadyAddedLineItem = cart?.lineItems?.find((item) => item.variant.sku === variant.sku);
        const alreadyAddedCheaperSetLineItem = cart?.lineItems?.find(
            (item) =>
                item.variant.sku === variant.sku && item.custom.productRelationLineItemKey === alreadyAddedLineItem?.key
        );

        if (alreadyAddedLineItem && alreadyAddedCheaperSetLineItem) {
            // Increase quantity of cheaper set (reduce maxQuantity by one as the main-product does count on this limit)
            const maxQuantity = alreadyAddedCheaperSetLineItem.variant.attributes.maxOrderQuantity - 1;
            const newQuantity = quantity + alreadyAddedCheaperSetLineItem.quantity;

            const cartResponse = await this.client.setLineItemQuantity(
                alreadyAddedCheaperSetLineItem.key,
                Math.min(newQuantity, maxQuantity)
            );

            return {
                cart: cartResponse,
                exceededMaxQuantity: newQuantity > maxQuantity,
            };
        } else if (alreadyAddedLineItem) {
            // Add the cheaper set
            const cheaperSetParams = {
                sku: variant.sku,
                quantity,
                partner,
                relationType: 'upsell' as BrxRelationType,
                lineItemKey: alreadyAddedLineItem.key,
                wkoSku,
                facebookTrackingId,
            };

            const cartResponse = await this.client.addLineItem(cheaperSetParams);
            return {
                cart: cartResponse,
                exceededMaxQuantity: false,
            };
        }

        const mainProductLineItem = cart?.lineItems?.find((item) => item.variant.sku === relation?.mainProductSku);

        // Add normal line item
        const addLineItemParams = {
            sku: variant.sku,
            quantity: 1,
            partner,
            relationType: relation?.relationType,
            lineItemKey: mainProductLineItem?.key,
            subscriptionInterval,
            wkoSku,
            facebookTrackingId,
        };
        const updatedCart = await this.client.addLineItem(addLineItemParams);

        // This seems to be an eslint error:
        // eslint-disable-next-line require-atomic-updates
        alreadyAddedLineItem = updatedCart.lineItems?.find((item) => item.variant.sku === variant.sku);

        if (quantity > 1 && alreadyAddedLineItem) {
            // Add the cheaper set
            const cheaperSetParams = {
                sku: variant.sku,
                quantity: quantity - 1,
                partner,
                relationType: 'upsell' as BrxRelationType,
                lineItemKey: alreadyAddedLineItem.key,
                facebookTrackingId,
            };

            const cartResponse = await this.client.addLineItem(cheaperSetParams);

            return {
                cart: cartResponse,
                exceededMaxQuantity: false,
            };
        }

        return {
            cart: updatedCart,
            exceededMaxQuantity: false,
        };
    }

    private async addLineItemToCart({
        partner,
        quantity,
        relation,
        subscriptionInterval,
        variant,
        wkoSku,
        facebookTrackingId,
    }: AddLineItemProps): Promise<{ cart: Cart; exceededMaxQuantity: boolean }> {
        const { cart } = this.store.getState().cart;
        const alreadyAddedLineItem = cart?.lineItems.find(
            (item) =>
                item.variant.sku === variant.sku &&
                item.lineItemMode !== 'GiftLineItem' &&
                item.custom.productRelationType === mapBrxRelationTypeToGatewayRelationType(relation?.relationType)
        );
        let hasCheaperSetPrice = false;
        if (variant.mightHaveCheaperSets) {
            try {
                const { actualPrice, originalListPrice } = await this.productClient.getProductPrices(
                    variant.sku,
                    'UPSELL'
                );
                hasCheaperSetPrice = Boolean(
                    (actualPrice?.centAmount && originalListPrice?.centAmount === undefined) ||
                        (actualPrice?.centAmount &&
                            originalListPrice?.centAmount &&
                            actualPrice?.centAmount < originalListPrice?.centAmount)
                );
            } catch {
                hasCheaperSetPrice = false;
            }
        }

        if (hasCheaperSetPrice) {
            return this.handleCheaperSet({
                partner,
                quantity,
                relation,
                subscriptionInterval,
                variant,
                wkoSku,
                facebookTrackingId,
            });
        } else if (alreadyAddedLineItem) {
            const maxQuantity = alreadyAddedLineItem.variant.attributes.maxOrderQuantity;
            const newQuantity = quantity + alreadyAddedLineItem.quantity;

            const cartResponse = await this.client.setLineItemQuantity(
                alreadyAddedLineItem.key,
                Math.min(newQuantity, maxQuantity)
            );

            return {
                cart: cartResponse,
                exceededMaxQuantity: newQuantity > maxQuantity,
            };
        }

        const mainProductLineItem = cart?.lineItems?.find((item) => item.variant.sku === relation?.mainProductSku);

        const addLineItemParams = {
            sku: variant.sku,
            quantity,
            partner,
            relationType: mainProductLineItem ? relation?.relationType : undefined,
            lineItemKey: mainProductLineItem?.key,
            subscriptionInterval,
            wkoSku,
            facebookTrackingId,
        };

        const cartResponse = await this.client.addLineItem(addLineItemParams);
        return {
            cart: cartResponse,
            exceededMaxQuantity: false,
        };
    }

    async setLineItemQuantity(lineItemKey: string, quantity: number): Promise<void> {
        this.store.dispatch(loadingCartStarted());
        const cart = await this.client.setLineItemQuantity(lineItemKey, quantity);
        this.store.dispatch(cartReceived({ cart }));
    }

    async removeLineItem(lineItemKey: string): Promise<void> {
        this.store.dispatch(loadingCartStarted());

        const cart = await this.client.removeLineItem(lineItemKey);
        this.store.dispatch(cartReceived({ cart }));
    }

    async deleteCart(): Promise<void> {
        this.store.dispatch(loadingCartStarted());
        await this.client.deleteCart();
        this.store.dispatch(cartReset());
        this.store.dispatch(installmentPlanReset());
    }

    async setAddresses(addresses: AddressForm, isGuestCheckout?: boolean): Promise<Cart | undefined> {
        /*
         * TODO: Check if we can skip sending the address if it's the same as the current one
         * Pre-work already done. Check if this can still be used in some form.
         * const cartData = this.store.getState().cart.cart;
         * const billingAddress = cartData?.billingAddress;
         * const shippingAddress = cartData?.shippingAddress;
         *
         * const sameBilling = type === AddressType.billing && isEqual(address, billingAddress);
         * const sameShipping = type === AddressType.shipping && isEqual(address, shippingAddress);
         * const sameBoth = type === AddressType.billingAndShipping &&
         *     cartData?.addressesEqual &&
         *     isEqual(address, billingAddress);
         *
         * if (sameBilling || sameShipping || sameBoth) {
         *     return;
         * }
         */
        try {
            this.store.dispatch(loadingCartStarted());
            const cart = await this.client.setAddresses(addresses, isGuestCheckout);
            this.store.dispatch(cartReceived({ cart }));
            this.store.dispatch(installmentPlanReset());

            return cart;
        } catch (ignore) {
            this.store.dispatch(loadingCartFailed());
        }

        return undefined;
    }

    async setAddressExpressCheckout(): Promise<void> {
        this.store.dispatch(loadingCartStarted());
        const cart = await this.client.setAddressExpressCheckout();
        this.store.dispatch(cartReceived({ cart }));
    }

    async removeDiscountCode(discountCode: string): Promise<void> {
        this.store.dispatch(loadingCartStarted());

        const cart = await this.client.removeDiscountCode(discountCode);

        this.store.dispatch(cartReceived({ cart }));
        this.store.dispatch(installmentPlanReset());
    }

    async getInstallmentPlan(dateOfBirth?: string): Promise<void> {
        const installmentPlan = await this.client.getInstallmentPlan(dateOfBirth!);
        this.store.dispatch(installmentPlanReceived({ installmentPlan }));
    }

    async restoreCart(cartId: string, partner: string): Promise<void> {
        this.store.dispatch(loadingCartStarted());
        const cart = await this.client.restoreCart(cartId, partner);
        this.store.dispatch(cartReceived({ cart }));
    }

    async unfreezeCart(): Promise<void> {
        this.store.dispatch(loadingCartStarted());
        const cart = await this.client.unfreezeCart();
        this.store.dispatch(cartReceived({ cart }));
    }
}
