import TextCompound, { TextCompoundProps } from '@mediashop/base/pattern/atom/TextCompound';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import Input from '@mediashop/base/pattern/atom/Input';
import { BaseProps, BrxAnchor, BrxBackground, BrxCTAButton, BrxHeadlines } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { EMAIL_REGEX } from '@mediashop/app/constants/emailRegex';
import LinkHelper from '@mediashop/app/helper/LinkHelper';
import { useNewsletterRegistration } from '@mediashop/app/hooks/api/useNewsletterRegistration';
import CTAButton from '@mediashop/base/pattern/atom/CTAButton';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';
import AvailableContentSpace from '../AvailableContentSpace';

const componentName = 'newsletter';

type NewsletterProps = BaseProps & {
    anchor?: BrxAnchor;
    availableContentSpace: string;
    background: BrxBackground;
    ctaButton: BrxCTAButton;
    descriptionTextCompound: TextCompoundProps;
    headline: BrxHeadlines;
    legalTextCompound: TextCompoundProps;
    redirectUrl?: string;
};

const validateEmail = (emailAddress: string) => {
    let error = '';

    if (!emailAddress) {
        error = 'newsletter.emailEmptyError';
    }

    if (emailAddress && !emailAddress.match(EMAIL_REGEX)) {
        error = 'newsletter.invalidEmail';
    }

    return error;
};

function Newsletter({
    anchor,
    availableContentSpace,
    background,
    ctaButton,
    descriptionTextCompound,
    headline,
    legalTextCompound,
    redirectUrl,
}: NewsletterProps) {
    const [emailAddress, setEmailAddress] = useState(EMPTY_STRING);
    const [emailError, setEmailError] = useState(EMPTY_STRING);
    const [companyTransactions, setCompanyTransactions] = useState(EMPTY_STRING);

    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const onRegistrationSuccess = () => {
        if (redirectUrl) {
            if (LinkHelper.isAbsoluteUrl(redirectUrl)) {
                window.location.href = redirectUrl;
            } else {
                navigate(redirectUrl);
            }
        }
    };

    const { mutate: registerForNewsletter, isPending } = useNewsletterRegistration({
        onSuccess: onRegistrationSuccess,
    });

    const subscribeToNewsletter = (event) => {
        event.preventDefault();

        const error = validateEmail(emailAddress);
        setEmailError(error);

        if (!error) {
            registerForNewsletter({ emailAddress, botPrevention: companyTransactions });
        }
    };

    return (
        <BackgroundColorWrapper backgroundColor={background.color} hexBackgroundColor={background.backgroundColorHex}>
            <ContentWrapper id={anchor?.id}>
                <AvailableContentSpace contentSpace={availableContentSpace}>
                    <div className={componentName}>
                        <Headlines {...headline} />
                        <TextCompound {...descriptionTextCompound} className={`${componentName}__description`} />
                        <form className={`${componentName}__form`} onSubmit={subscribeToNewsletter}>
                            <div className={`${componentName}__form-input-wrapper`}>
                                <Input
                                    id="newsletter_email"
                                    name="newsletter_email"
                                    type="email"
                                    required
                                    className={`${componentName}__input`}
                                    value={emailAddress}
                                    onChange={(event) => setEmailAddress(event.target.value)}
                                    error={emailError ? formatMessage({ id: emailError }) : undefined}
                                    label={formatMessage({ id: 'newsletter.inputPlaceholder' })}
                                    style={
                                        ctaButton.backgroundColorHex
                                            ? { borderColor: ctaButton.backgroundColorHex }
                                            : {}
                                    }
                                />
                                <CTAButton
                                    className={`${componentName}__button`}
                                    onClick={subscribeToNewsletter}
                                    loading={isPending}
                                    ctaButton={ctaButton}
                                >
                                    {ctaButton.text}
                                </CTAButton>
                            </div>
                            <Input
                                className={`${componentName}__company-transactions`}
                                value={companyTransactions}
                                onChange={(event) => setCompanyTransactions((event.target as HTMLInputElement).value)}
                                name="company-transactions"
                                autoComplete="__away"
                            />
                        </form>
                        <TextCompound {...legalTextCompound} className={`${componentName}__legal-text`} />
                    </div>
                </AvailableContentSpace>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.Newsletter', Newsletter);
